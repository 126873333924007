.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    cursor: auto;
    justify-content: center;
}

.modal-content {
    width: 500px;
    background-color: #fac2fe;
    background-image: linear-gradient(135deg, #fbc3ff 0%, #f6fbbb 52%, #b3ffd4 90%);
}

.modal-heade .modal-footer {
    padding: 10px;
}

.modal-title {
    margin: 0;
}

.modal-body {
    padding: 15px;
}