footer {
  margin-top: 50px;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  /* background-color: cornflowerblue; */
  color: black;
  font-size: 27px;
}

.App-header {
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cards {
  align-items: left !important;
  width: 77vw;
}

.App-link {
  color: #61dafb;
}

.hoverbackground:hover {
  background: #e7f5ff !important;
} 
.hoverbackground {
  background: rgb(241,249,236);
  background: linear-gradient(90deg, rgba(241,249,236,1) 0%, rgba(235,245,240,1) 100%);
}

.shadow {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 8px 30px -6px;
  border: 0;
}

.card-body-stats {
  padding: 1.2rem 2rem;
}

.percent-stats {
  font-size: 15px;
  color: gray;
}

.copy {
  margin-top: 50vh;
}

.App-link {
  font-family: 'Noto Sans KR', sans-serif;
  background: linear-gradient(135deg, #FFCCFF 0%, #FFFFCC 50%, #CCFFFF 100%) fixed;
  background-color: #FFFFFF;
  border-radius: 99999px;
  border-color: #000000;
  border-width: 2px;
  border-style: solid;
  color: #000000;
  font-size: calc(10px + 1vmin);
  font-weight: 400;
  line-height: inherit;
  padding: calc(0.667em + 2px) calc(1.333em + 2px);
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.App-link:hover {
  background: linear-gradient(135deg, #CCFFFF 0%, #FFFFCC 50%, #FFCCFF 100%) fixed;
  color: #000;
  text-decoration: none;
}